import React from "react";
import PageTransition from "gatsby-plugin-page-transitions";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../components/Layout/Layout";
import Section from "../components/Section/Section";
import SEO from "../components/Seo/Seo";
import { P, H2, H3, H5 } from "../components/Typography/Typography";

const PrivacyPolicyPage = () => (
  <PageTransition>
    <Layout>
      <SEO title="Privacy Policy" />
      <Section className="sf-privacy-policy">
        <Container>
          <Row>
            <Col>
              <H2>Privacy Policy</H2>
            </Col>
          </Row>
          <Row>
            <Col>
              <P>
                Softbinator Foundation (nume juridic Asociația Softbinator)
              </P>
              <P>
                Donatorii noștri sunt atât companii, cât și persoane fizice. De
                aceea, noi strângem și folosim informații personale referitoare
                la indivizi ca parte a activităților noastre. Acest lucru îl
                facem, de manieră generală, pentru a le putea oferi
                susținătorilor noștri informații despre proiectele noastre.
              </P>
              <P>
                Politica de confidențialitate stabilește modul în care
                Softbinator Foundation (nume juridic Asociația Softbinator)
                strânge și folosește informațiile personale privind donatorii.
              </P>
              <P>
                Intimitatea dumneavoastră este una din obligațiile fundamentale
                ale Softbinator Foundation (nume juridic Asociația 
                Softbinator). Ca urmare, suntem deosebit de atenți în procesarea
                datelor dumneavoastră personale în conformitate cu principiile
                prevăzute de legislația privind protecția datelor aplicabilă în
                România, inclusiv Regulamentul 2016/679 privind protecția
                persoanelor fizice în ceea ce privește procesarea datelor
                personale și circulația liberă a acestora, care abrogă Directiva
                95/46/EC ("GDPR"). Datele personale reprezintă orice informație
                privind o persoană fizică identificată sau identificabilă; o
                persoană identificabilă este acea persoană ce poate fi
                identificată direct sau indirect, în special prin referirea la
                un număr de identificare sau unul ori mai mulți factori
                specifici identității sale fizice, psihologice, mentale,
                economice, culturale sau sociale.
              </P>
              <P>
                Politica de confidențialitate privește datele personale ale
                membrilor noștri, ale partenerilor de afaceri, ale altor
                persoane ce contactează sau vizitează Softbinator Foundation
                (nume juridic Asociația Softbinator), ale potențialilor
                angajați, interni sau asociați și se aplică datelor colectate
                prin intermediul site-ului https://softbinator.foundation,
                precum și tuturor celorlalte date personale pe care Softbinator
                Foundation (nume juridic Asociația Softbinator)le colectează
                prin intermediul e-mailului sau al altor contacte offline.
                Politica de confidențialitate descrie:
              </P>
              <P>
                <ul>
                  <li>
                    scopurile pentru care Softbinator Foundation (nume juridic
                    Asociația Softbinator) colectează și utilizează datele
                    dumneavoastră personale;
                  </li>
                  <li>motivul procesării pentru asemenea scopuri</li>
                  <li>
                    categoriile de date personale pe care Softbinator Foundation
                    (nume juridic Asociația Softbinator) le colectează de la
                    dumneavoastră și le procesează;
                  </li>
                  <li>durata procesării unor asemenea date;</li>
                  <li>
                    drepturile dumneavoastră în calitate de subiecți purtători
                    de date și modul în care vi le puteți exercita;
                  </li>
                  <li>cui poate dezvălui datele dumneavoastră personale.</li>
                </ul>
              </P>
              <P>
                <strong>
                  În contextul interacțiunii cu Softbinator Foundation (nume
                  juridic Asociația Softbinator), dumneavoastră ca persoană
                  fizică puteți fi supus unor activități de procesare a datelor
                  pe care le efectuează Softbinator Foundation (nume juridic
                  Asociația Softbinator):
                </strong>
              </P>

              <H3>
                1. Dacă sunteți donator sau potențial donator al Softbinator
                Foundation (nume juridic Asociația Softbinator)
              </H3>

              <H5>
                1.1. Oferirea de informații referitoare la calitatea de donator
                și despre platforma de strângere de fonduri a asociației.
              </H5>

              <P>
                Softbinator Foundation (nume juridic Asociația Softbinator)
                utilizează datele dumneavoastră personale relevante pentru a
                oferi informații privind calitatea de donator, proiectele în
                care vor fi investiți banii dumneavoastră și stadiul acestor
                proiecte.
              </P>

              <H5>1.2. Comunicarea cu dumneavoastră</H5>

              <P>
                Softbinator Foundation (nume juridic Asociația Softbinator)
                utilizează datele dumneavoastră de contact pentru a comunica cu
                dumneavoastră referitor la calitatea de donator, la activitatea
                noastră si orice alte informații relevante legate de proiectele
                noastre. Softbinator Foundation (nume juridic Asociația 
                Softbinator) se bazează în acest caz pe acceptul dumneavoastră
                privind deciziile organizației ca bază a procesării.
              </P>

              <H5>1.3. Trimiterea de informări</H5>

              <P>
                Softbinator Foundation (nume juridic Asociația Softbinator)
                poate utiliza datele dumneavoastră de contact pentru a vă oferi
                informări în format electronic referitoare la activitatea
                organizației, stadiul proiectelor, comunicate de presă ale
                asociației, documente de poziție, evenimente, invitații si orice
                alte informații relevante pentru activitatea întreprinsă de ONG,
                însă doar dacă v-ați înscris sau dacă v-ați dat consimțământul
                în mod expres pentru acest tip de procesare. Vă puteți retrage
                consimțământul oricând, exprimându-vă opțiunea de a nu mai primi
                informări pe viitor printr-un clic pe butonul "Unsubscribe"
                atunci când primiți respectivul e-mail sau scriind direct la
                adresele de e-mail dorin.minea@softbinator.ro sau
                contact@softbinator.ro.
              </P>

              <H3>
                2. Dacă sunteți voluntar al Softbinator Foundation (nume juridic
                Asociația Softbinator)
              </H3>

              <H5>
                2.1. Furnizarea de informații asupra activității Softbinator
                Foundation (nume juridic Asociația Softbinator)
              </H5>

              <P>
                Softbinator Foundation (nume juridic Asociația Softbinator) vă
                utilizează datele personale relevante pentru a furniza
                informații privind activitățile cu voluntarii, informări
                referitoare la proiecte, rapoarte de activitate și orice alte
                informații specifice.
              </P>

              <H5>2.2. Comunicarea cu dumneavoastră</H5>

              <P>
                Softbinator Foundation (nume juridic Asociația Softbinator)
                folosește datele dumneavoastră de contact pentru a comunica cu
                dumneavoastră privitor la evenimentele organizației și
                activitățile în care vă puteți implica. Softbinator Foundation
                (nume juridic Asociația Softbinator) se bazează de asemenea în
                acest caz pe interesul legitim al voluntarului.
              </P>

              <H5>
                2.3. Trimiterea de buletine informative privind activitatea
                asociației
              </H5>

              <P>
                Softbinator Foundation (nume juridic Asociația Softbinator) va
                putea de asemenea folosi datele dumneavoastră de contact pentru
                a vă furniza informări în format electronic referitoare la
                activitatea organizației, stadiul proiectelor, comunicate de
                presă ale asociației, documente de poziție, evenimente,
                invitații și orice alte informații relevante pentru activitatea
                întreprinsă de ONG, însă doar dacă v-ați înscris sau dacă v-ați
                dat consimțământul în mod expres pentru acest tip de procesare.
                Vă puteți retrage consimțământul oricând, exprimându-vă opțiunea
                de a nu mai primi informări pe viitor printr-un clic pe butonul
                „Unsubscribe” atunci când primiți respectivul e-mail sau scriind
                direct la adresele de e-mail dorin.minea@softbinator.ro sau
                contact@softbinator.ro.
              </P>

              <H3>
                3. Dacă sunteți partener sau furnizor de servicii al Softbinator
                Foundation (nume juridic Asociația Softbinator)
              </H3>

              <H5>3.1. Menținerea relațiilor contractuale cu dumneavoastră</H5>

              <P>
                Softbinator Foundation (nume juridic Asociația Softbinator) va
                utiliza datele personale relevante pentru a ne menține relația
                contractuală cu dumneavoastră. Softbinator Foundation (nume
                juridic Asociația Softbinator) se sprijină în acest caz pe
                punerea în aplicare a acordului contractual ca bază a procesării
                datelor.
              </P>

              <H5>3.2. Comunicarea cu dumneavoastră</H5>

              <P>
                Softbinator Foundation (nume juridic Asociația Softbinator)
                folosește datele dumneavoastră de contact pentru comunicarea cu
                dumneavoastră privitor la orice aspecte relevante conexe
                contractului. Organizația se sprijină în acest caz pe punerea în
                aplicare a acordului ca bază a procesării datelor.
              </P>

              <H3>
                4. Dacă sunteti reprezentant, persoană de contact, angajat sau
                orice alt colaborator al Softbinator Foundation (nume juridic
                Asociația Softbinator)
              </H3>

              <H5>4.1. Menținerea relației noastre contractuale</H5>

              <P>
                Folosim datele dumneavoastră personale relevante pentru a ne
                menține relația contractuală. Ne bazăm în acest caz pe interesul
                legitim, asigurând gestionarea adecvată a relației contractuale.
              </P>

              <H5>4.2. Comunicarea cu dumneavoastră</H5>

              <P>
                Softbinator Foundation (nume juridic Asociația Softbinator)
                folosește datele dumneavoastră de contact pentru comunicarea cu
                dumneavoastră privitor la orice aspecte relevante conexe
                contractului. Ne bazăm, de asemenea, în acest caz pe interesul
                legitim de a asigura ca relația contractuală este pusă în
                aplicare în mod adecvat.
              </P>

              <H3>
                5. Dacă sunteți utilizator al site-ului nostru –
                https://softbinator.foundation
              </H3>

              <P>
                Folosim datele personale pe care le preluăm de la dumneavoastră
                când vizitați site-urile noastre pentru a monitoriza traficul, a
                îmbunătăți conținutul site-ului și a vă informa despre stadiul
                proiectelor noastre. Ne bazăm această activitate de procesare a
                datelor pe interesul nostru legitim de a asigura funcționarea
                adecvată a site-ului, îmbunătățirea lui, precum și o bună
                informare a dumneavoastră cu privire la stadiul proiectelor în
                care ați ales să vă implicați.
              </P>

              <P>
                Pentru toate categoriile de date de mai sus, Softbinator
                Foundation (nume juridic Asociația Softbinator) poate, de
                asemenea, să proceseze datele dumneavoastră în contextul
                schimbărilor de structură sau al altor tranzacții similare ce
                implică Softbinator Foundation (nume juridic Asociația 
                Softbinator). În acest caz, baza procesării poate fi reprezentată
                de obligațiile legale, iar Softbinator Foundation (nume juridic
                Asociația Softbinator) este obligată legal să dezvăluie
                anumite date personale autorităților publice.
              </P>

              <H3>6. Furnizarea de date personale</H3>

              <P>
                Atunci când vi se cer date în mod direct, Softbinator Foundation
                (nume juridic Asociația Softbinator) va solicita să îi dați
                toate categoriile de date personale în scopurile menționate mai
                sus, pentru că în caz contrar nu vom putea să ne desfășurăm
                activitatea (ce include, printre altele, furnizarea către
                dumneavoastră a informațiilor privind activitatea noastră).
              </P>

              <P>
                Dacă furnizați Softbinator Foundation (nume juridic Asociația
                Asociația Softbinator) datele personale ale altor persoane fizice, vă
                rugăm să luați legătura cu ele înaintea dezvăluirii unor date,
                pentru a le comunica modul în care Softbinator Foundation (nume
                juridic Asociația Softbinator) intenționează să le proceseze
                datele personale, așa cum a fost descris în Politica de
                Confidențialitate.
              </P>

              <H3>7. Dezvăluirea datelor dumneavoastră personale</H3>

              <P>
                Deși de regulă nu dezvăluim datele dumneavoastră personale
                niciunei terțe părți, este posibil ca, în interesul
                dumneavoastră și dacă este necesar, să facem dezvăluiri de date
                personale instanțelor de judecată și autorităților relevante în
                contextul activităților Softbinator Foundation (nume juridic
                Asociația Softbinator).
              </P>

              <H3>8. Durata procesării</H3>

              <P>
                Intenționăm să păstrăm datele dumneavoastră în conformitate cu
                politicile noastre interne și cu obligațiile legale ce ne revin.
              </P>

              <P>
                În caz că datele nu sunt colectate în contextul calității de
                donator/voluntar ori al unui acord de asistență, astfel de date
                vor fi păstrate atât timp cât este necesar pentru atingerea
                scopului în care au fost colectate.
              </P>

              <H3>9. Drepturile dumneavoastră</H3>

              <P>
                În calitatea dumneavoastră de subiecți, GDPR vă oferă o serie de
                drepturi ce includ:
              </P>

              <P>
                <ul>
                  <li>
                    <strong>dreptul la acces</strong> – vă permite să obtineți
                    confirmarea că datele dumneavoastră personale sunt procesate
                    de noi și, în caz afirmativ, detaliile relevante ale unor
                    asemenea activități de procesare;
                  </li>
                  <li>
                    <strong>dreptul la rectificări</strong> – vă permite
                    corectarea datelor dacă sunt inexacte;
                  </li>
                  <li>
                    <strong>dreptul la radiere</strong> – vă permite să obțineți
                    ștergerea datelor dumneavoastră personale în anumite cazuri
                    (e.g. dacă datele nu mai sunt necesare scopului pentru care
                    au fost colectate);
                  </li>
                  <li>
                    <strong>dreptul la restricție</strong> – vă permite să
                    obțineți restricționarea procesării datelor dumneavoastră
                    personale în anumite cazuri (e.g. atunci când contestați
                    exactitatea datelor dumneavoastră personale, oferindu-ne
                    astfel posibilitatea de a le verifica exactitatea într-un
                    anumit interval de timp);
                  </li>
                  <li>
                    <strong>dreptul de a obiecta</strong> – vă permite să
                    obiectați împotriva procesării ulterioare a datelor
                    dumneavoastră personale, în conditiile și limitele stabilite
                    de lege;
                  </li>
                  <li>
                    <strong>dreptul la portarea datelor</strong> – vă permite să
                    primiți datele personale ce vă privesc și pe care ni le-ați
                    furnizat, într-un format structurat ce se folosește în mod
                    obișnuit și poate fi citit de echipamente, sau ca aceste
                    date să fie transmise unui alt controlor de date.
                  </li>
                </ul>
              </P>

              <P>
                Suntem bucuroși să vă asigurăm exercitarea acestor drepturi.
              </P>

              <P>
                Vă puteți exercita drepturile mai sus menționate și puteți afla
                mai multe despre astfel de drepturi prin completarea împreună cu
                noi, în calitate de controlori de date, a unei solicitări scrise
                catre Softbinator Foundation (nume juridic Asociația 
                Softbinator) – intrarea Horbotei, nr 2, bl M10, ap 68, Sector 3
                București sau prin trimiterea unui e-mail la adresa
                dorin.minea@softbinator.ro. Aveți, de asemenea, dreptul de a
                depune o plângere la autoritatea de protecție a datelor. Suntem
                hotărâți să vă tratăm întotdeauna cererile cu cea mai mare
                atenție și să ne ocupăm de orice solicitări ați putea avea în
                cel mai scurt timp posibil.
              </P>

              <H3>10. Versiuni ale acestei politici de confidențialitate</H3>

              <P>
                Această Politică de Confidențialitate a intrat în vigoare la
                data de 25 mai 2018. Ea poate fi actualizată periodic.
              </P>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  </PageTransition>
);

export default PrivacyPolicyPage;
